import React, { useState, useEffect } from 'react';
import { ChakraProvider, Input, useToast, Box, Table, Thead, Tbody, Tr, Th, Td, Spinner, Button, Select, IconButton, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, FormControl, FormLabel } from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import axios from 'axios';
import './Games.css';

function Games() {
    const [games, setGames] = useState([]);  // All games fetched from API
    const [filteredGames, setFilteredGames] = useState([]);  // Games filtered by search query and ranking
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(1);  // Current page
    const [totalPages, setTotalPages] = useState(1);  // Total pages based on filtered games
    const [loading, setLoading] = useState(false);
    const [rankingOrder, setRankingOrder] = useState('asc');  // Ranking filter order
    const [isRankingModalOpen, setIsRankingModalOpen] = useState(false);  // State to control ranking modal
    const [editingGame, setEditingGame] = useState({});  // State to hold the game being edited
    const toast = useToast();
    const itemsPerPage = 20;  // Number of items per page

    useEffect(() => {
        fetchGames();
    }, []);

    useEffect(() => {
        applyFiltersAndSorting();
    }, [searchQuery, games, rankingOrder]);

    const fetchGames = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/retrieve-games`, {
                params: {
                    limit: 10000,  // Fetch all games (or a very large number)
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            if (response.data.success) {
                setGames(response.data.data);
                setFilteredGames(response.data.data);  // Initially, all games are displayed
                setTotalPages(Math.ceil(response.data.data.length / itemsPerPage));
            } else {
                toast({
                    title: "Failed to fetch games.",
                    description: response.data.message,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        } catch (err) {
            toast({
                title: "Error fetching games",
                description: err.toString(),
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const applyFiltersAndSorting = () => {
        let filtered = games.filter((game) =>
            game.gameName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            game.providerName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            game.gameCode.toLowerCase().includes(searchQuery.toLowerCase())
        );

        // Sort the filtered games based on ranking, with ranking 0 always at the end
        filtered = filtered.sort((a, b) => {
            if (a.ranking === 0) return 1;  // Move games with ranking 0 to the end
            if (b.ranking === 0) return -1;  // Move games with ranking 0 to the end
            if (rankingOrder === 'asc') {
                return a.ranking - b.ranking;
            } else {
                return b.ranking - a.ranking;
            }
        });

        setFilteredGames(filtered);
        setTotalPages(Math.ceil(filtered.length / itemsPerPage));
        setPage(1);  // Reset to the first page after filtering
    };

    // Open the ranking modal and set the game being edited
    const handleRankingChange = (game) => {
        setEditingGame(game);
        setIsRankingModalOpen(true);
    };

    // Submit the updated ranking
    const handleSubmitEditRanking = async () => {
        const updatedRankingData = { ranking: editingGame.ranking };

        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/api/admin/game/edit-ranking/${editingGame.gameId}`, updatedRankingData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });

            // Update the specific game in the state
            setGames((prevGames) =>
                prevGames.map((game) =>
                    game.gameId === editingGame.gameId ? { ...game, ranking: editingGame.ranking } : game
                )
            );

            setIsRankingModalOpen(false);
            toast({
                title: "Game ranking updated.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });

            // Re-apply filters and sorting after updating the ranking
            applyFiltersAndSorting();
        } catch (err) {
            toast({
                title: "Error updating game ranking",
                description: err.toString(),
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditingGame({ ...editingGame, [name]: value });
    };

    // Get games for the current page
    const currentPageGames = filteredGames.slice((page - 1) * itemsPerPage, page * itemsPerPage);

    return (
        <ChakraProvider>
            <Box className="App" p={4}>
                <Box className="search-bar-container" mb={4}>
                    <Input
                        type="text"
                        placeholder="Search game..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </Box>

                {/* Ranking Order Filter */}
                <Box mb={4}>
                    <Select
                        value={rankingOrder}
                        onChange={(e) => setRankingOrder(e.target.value)}
                        placeholder="Select Ranking Order"
                        width="200px"
                    >
                        <option value="asc">Ranking Ascending</option>
                        <option value="desc">Ranking Descending</option>
                    </Select>
                </Box>

                {loading ? (
                    <Spinner size="xl" />
                ) : (
                    <>
                        <Box className="scrollable-container">
                            <Table variant="striped" colorScheme="gray">
                                <Thead>
                                    <Tr>
                                        <Th>Game Code</Th>
                                        <Th>Provider</Th>
                                        <Th>Game Name</Th>
                                        <Th>Ranking</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {currentPageGames.map((game) => (
                                        <Tr key={game.gameId}>
                                            <Td>{game.gameCode}</Td>
                                            <Td>{game.providerName}</Td>
                                            <Td>{game.gameName}</Td>
                                            <Td>
                                                <Box display="flex" alignItems="center">
                                                    <span>{game.ranking}</span>
                                                    <IconButton
                                                        icon={<EditIcon />}
                                                        size="sm"
                                                        ml={2}
                                                        onClick={() => handleRankingChange(game)}
                                                    />
                                                </Box>
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </Box>

                        <Box display="flex" justifyContent="center" mt={4}>
                            <Button onClick={() => setPage(1)} disabled={page === 1} mr={2}>
                                First
                            </Button>
                            <Button onClick={() => setPage((prev) => Math.max(prev - 1, 1))} disabled={page === 1} mr={2}>
                                Previous
                            </Button>
                            {Array.from({ length: totalPages }, (_, i) => i + 1).slice(Math.max(0, page - 5), Math.min(totalPages, page + 4)).map((pageNumber) => (
                                <Button
                                    key={pageNumber}
                                    onClick={() => setPage(pageNumber)}
                                    colorScheme={pageNumber === page ? 'blue' : 'gray'}  // Highlight current page in blue
                                    mr={1}
                                >
                                    {pageNumber}
                                </Button>
                            ))}
                            <Button onClick={() => setPage((prev) => Math.min(prev + 1, totalPages))} disabled={page === totalPages} mr={2}>
                                Next
                            </Button>
                            <Button onClick={() => setPage(totalPages)} disabled={page === totalPages}>
                                Last
                            </Button>
                        </Box>
                    </>
                )}

                {/* Edit Game Ranking Modal */}
                <Modal isOpen={isRankingModalOpen} onClose={() => setIsRankingModalOpen(false)}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Edit Game Ranking (ID: {editingGame?.gameId})</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            <FormControl>
                                <FormLabel>Game Ranking</FormLabel>
                                <Input
                                    name="ranking"
                                    type="number"
                                    value={editingGame?.ranking}
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={handleSubmitEditRanking}>
                                Save Changes
                            </Button>
                            <Button onClick={() => setIsRankingModalOpen(false)}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Box>
        </ChakraProvider>
    );
}

export default Games;
