import React, { useState, useEffect } from 'react';
import { ChakraProvider, Input, useToast, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, FormControl, FormLabel, Switch, Box, Table, Thead, Tbody, Tr, Th, Td, IconButton } from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import axios from 'axios';
import './HomeBanner.css';

function HomeBanner() {
    const [banners, setBanners] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isActivateDeactivateModalOpen, setIsActivateDeactivateModalOpen] = useState(false);
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [activeDeactiveBanner, setActiveDeactiveBanner] = useState(null);
    const [editingImageBanner, setEditingImageBanner] = useState(null);
    const [imagePreview, setImagePreview] = useState('');
    const [imageFile, setImageFile] = useState(null);
    const toast = useToast();

    useEffect(() => {
        fetchBanners();
    }, []);

    const fetchBanners = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/retrieve-home-banners`, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            if (response.data.success) {
                setBanners(response.data.data);
            } else {
                toast({
                    title: "Failed to fetch home banners.",
                    description: response.data.message,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        } catch (err) {
            toast({
                title: "Error fetching home banners",
                description: err.toString(),
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImageFile(file);
            const reader = new FileReader();
            reader.onload = (event) => {
                const img = new Image();
                img.src = event.target.result;
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    const maxDim = 512;
    
                    canvas.width = maxDim;
                    canvas.height = maxDim;
    
                    // Draw the image on canvas and resize it to 512x512
                    ctx.drawImage(img, 0, 0, maxDim, maxDim);
    
                    setImagePreview(canvas.toDataURL());
                };
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmitEditImage = async () => {
        if (!imageFile) {
            toast({
                title: "No image selected",
                description: "Please select an image to upload.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }
    
        const img = new Image();
        img.src = imagePreview;
        img.onload = async () => {
            const canvas = document.createElement('canvas');
            canvas.width = 512;
            canvas.height = 512;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, 512, 512);
            canvas.toBlob(async (blob) => {
                const formData = new FormData();
                formData.append('image', blob, 'bannerImage.png');
                formData.append('bannerName', editingImageBanner.homeBannerName);
    
                try {
                    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/upload-banner-image`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        },
                    });
    
                    const imageUrl = response.data.url;
    
                    await axios.put(`${process.env.REACT_APP_API_URL}/api/admin/home-banner/edit-image/${editingImageBanner.homeBannerId}`, {
                        bannerImage: imageUrl
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        },
                    });
    
                    fetchBanners();
                    setIsImageModalOpen(false);
                    toast({
                        title: "Banner image updated.",
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                    });
                } catch (err) {
                    const errorMessage = err.response && err.response.data.message ? err.response.data.message : "An unexpected error occurred.";
                    toast({
                        title: "Error updating banner image",
                        description: errorMessage,
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                    });
                }
            }, 'image/png');
        };
    };    

    const handleActivateDeactivateClick = (banner) => {
        setActiveDeactiveBanner(banner);
        setIsActivateDeactivateModalOpen(true);
    };

    const handleEditImageClick = (banner) => {
        setEditingImageBanner(banner);
        setImagePreview(banner.homeBannerImage);
        setIsImageModalOpen(true);
    };

    const handleSubmitActivateDeactivateBanner = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/home-banner/activate-deactivate/${activeDeactiveBanner.homeBannerId}`, {
                isActive: !activeDeactiveBanner.isActive
            }, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            fetchBanners();
            setIsActivateDeactivateModalOpen(false);
            toast({
                title: `Home banner ${activeDeactiveBanner.isActive ? "deactivated" : "activated"}.`,
                description: response.data.message,
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (err) {
            const errorMessage = err.response && err.response.data.message ? err.response.data.message : "An unexpected error occurred.";
            toast({
                title: "Error activating/deactivating home banner",
                description: errorMessage,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <ChakraProvider>
            <Box className="App" p={4}>
                <Box className="search-bar-container" mb={4}>
                    <Input
                        type="text"
                        placeholder="Search home banner..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
                    />
                </Box>

                <Table variant="striped" colorScheme="gray">
                    <Thead>
                        <Tr>
                            <Th>Banner ID</Th>
                            <Th>Banner Name</Th>
                            <Th>Banner Image</Th>
                            <Th>Is Active</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {banners
                            .filter((banner) => banner.homeBannerName.toLowerCase().includes(searchQuery))
                            .map((banner) => (
                                <Tr key={banner.homeBannerId}>
                                    <Td>{banner.homeBannerId}</Td>
                                    <Td>{banner.homeBannerName}</Td>
                                    <Td>
                                        <img src={banner.homeBannerImage} alt={banner.homeBannerName} style={{ width: '50px', height: '50px' }} />
                                    </Td>
                                    <Td>
                                        <Switch
                                            isChecked={banner.isActive}
                                            onChange={() => handleActivateDeactivateClick(banner)}
                                        />
                                    </Td>
                                    <Td>
                                        <Button size="sm" colorScheme="blue" onClick={() => handleEditImageClick(banner)} mr={2}>
                                            Edit Image
                                        </Button>
                                    </Td>
                                </Tr>
                            ))}
                    </Tbody>
                </Table>

                {/* Edit Banner Image Modal */}
                <Modal isOpen={isImageModalOpen} onClose={() => setIsImageModalOpen(false)}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Edit Banner Image (ID: {editingImageBanner?.homeBannerId})</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            <FormControl>
                                <img src={imagePreview} alt={editingImageBanner?.homeBannerName} style={{ width: '100%', height: 'auto', marginBottom: '10px' }} />
                                <FormLabel>Banner Image</FormLabel>
                                <Input
                                    type="file"
                                    accept="image/png, image/jpeg, image/jpg"
                                    onChange={handleImageChange}
                                />
                            </FormControl>
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={handleSubmitEditImage}>
                                Save Changes
                            </Button>
                            <Button onClick={() => setIsImageModalOpen(false)}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {/* Activate/Deactivate Banner Modal */}
                <Modal isOpen={isActivateDeactivateModalOpen} onClose={() => setIsActivateDeactivateModalOpen(false)}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>{activeDeactiveBanner?.isActive ? "Deactivate" : "Activate"} Home Banner</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            Are you sure you want to {activeDeactiveBanner?.isActive ? "deactivate" : "activate"} this home banner?
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={handleSubmitActivateDeactivateBanner}>
                                Yes
                            </Button>
                            <Button onClick={() => setIsActivateDeactivateModalOpen(false)}>No</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Box>
        </ChakraProvider>
    );
}

export default HomeBanner;
