// src/GamesByProvider.js
import React, { useState, useEffect } from 'react';
import { ChakraProvider, Input, useToast, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, FormControl, FormLabel, Switch, Box, Table, Thead, Tbody, Tr, Th, Td, IconButton, Spinner, Select} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { SketchPicker } from 'react-color';
import axios from 'axios';
import { ImageColorPicker } from 'react-image-color-picker';
import './GamesByProvider.css';

function GamesByProvider() {
    const { providerId } = useParams(); // Get providerId from URL parameters
    const location = useLocation();
    const navigate = useNavigate();
    const { providerName } = location.state; // Get providerName from state
    const [games, setGames] = useState([]);
    const [filteredGames, setFilteredGames] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [editingImageGame, setEditingImageGame] = useState(null);
    const [editingDisplayGame, setEditingDisplayGame] = useState(null);
    const [imagePreview, setImagePreview] = useState('');
    const [imageFile, setImageFile] = useState(null);
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [isDisplayModalOpen, setIsDisplayModalOpen] = useState(false);
    const [activeDeactiveGame, setActiveDeactiveGame] = useState(null);
    const [isActivateDeactivateModalOpen, setIsActivateDeactivateModalOpen] = useState(false);
    const [toggleNewGame, setToggleNewGame] = useState(null);
    const [isToggleNewGameModalOpen, setIsToggleNewGameModalOpen] = useState(false);
    const [isRankingModalOpen, setIsRankingModalOpen] = useState(false);
    const [isCategoryChangeModalOpen, setIsCategoryChangeModalOpen] = useState(false);
    const [categories, setCategories] = useState([]); // State to hold categories
    const [editCategory, setEditCategory] = useState(null);
    const [pickedColor, setPickedColor] = useState('');
    const [imageSrc, setImageSrc] = useState('');
    const [isGameNameModalOpen, setIsGameNameModalOpen] = useState(false);
    const [editingGameName, setEditingGameName] = useState('');
    const [isRtpModalOpen, setIsRtpModalOpen] = useState(false);
    const [editingRtpValue, setEditingRtpValue] = useState(); // Default RTP value or fetched value

    const [selectedCategory, setSelectedCategory] = useState('');
    const [isActiveFilter, setIsActiveFilter] = useState(false);
    const [isNewGameFilter, setIsNewGameFilter] = useState(false);
    const toast = useToast();

    const [editingGame, setEditingGame] = useState({
        gameId: '',
        gameName: '',
        ranking: 0,
        categoryId: '',
        gameImage: '',
        borderColor: '',
        gameFont: '',
        providerFont: '',
        fontColor: '',
        isCurved: 0,
        rtpValue: 0.0
    });

    useEffect(() => {
        fetchCategories();
        fetchGamesByProvider(providerId, page);
    }, [providerId]);

    useEffect(() => {
        filterGames(searchQuery);
    }, [games, searchQuery, selectedCategory, isActiveFilter, isNewGameFilter]);    

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/retrieve-game-categories`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            if (response.data.success) {
                setCategories(response.data.data); // Store categories in state
            } else {
                toast({
                    title: "Failed to fetch categories.",
                    description: response.data.message,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        } catch (err) {
            toast({
                title: "Error fetching categories",
                description: err.toString(),
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const fetchGamesByProvider = async (providerId, currentPage = page) => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/retrieve-games-by-provider/${providerId}`, {
                params: {
                    limit: 2000, // Fetch a large number to ensure all games are retrieved
                },
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            if (response.data.success) {
                setGames(response.data.data);
                setFilteredGames(response.data.data);
                setPage(currentPage);
            } else {
                toast({
                    title: "Failed to fetch games.",
                    description: response.data.message,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        } catch (err) {
            toast({
                title: "Error fetching games",
                description: err.toString(),
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const filterGames = (query) => {
        // If no filters are applied, reset to the full list of games
        if (!query && !selectedCategory && !isActiveFilter && !isNewGameFilter) {
            setFilteredGames(games);
            return;
        }

        let filtered = games;
        // Apply search query filter
        if (query) {
            const lowercasedQuery = query.toLowerCase();
            filtered = filtered.filter((game) =>
                game.gameName.toLowerCase().includes(lowercasedQuery) ||
                game.gameCode.toLowerCase().includes(lowercasedQuery)
            );
        }
    
        // Apply category filter
        if (selectedCategory) {
            filtered = filtered.filter((game) => game.categoryId === parseInt(selectedCategory));
        }
    
        // Apply isActive filter
        if (isActiveFilter) {
            filtered = filtered.filter((game) => game.isActive);
        }
    
        // Apply isNewGame filter
        if (isNewGameFilter) {
            filtered = filtered.filter((game) => game.isNewGame);
        }
    
        setFilteredGames(filtered);
        setPage(1);  // Reset to the first page
    };
    
    const handleEditImageClick = (game) => {
        setEditingImageGame(game);
        setImagePreview(game.gameImage);
        setIsImageModalOpen(true);
    };

    const handleEditDisplayClick = async (game) => {
        setPickedColor('');
        setEditingDisplayGame(game);
        setIsDisplayModalOpen(true);

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/admin/proxy-image`,
                {
                    params: { url: game.gameImage },
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                    responseType: 'blob', // Treat the image as a binary blob
                    withCredentials: true, // Include credentials like cookies
                }
            );
    
            // Create a URL for the image blob and set the state
            const imageUrl = URL.createObjectURL(response.data);
            setImageSrc(imageUrl);
        } catch (error) {
            toast({
                title: "Error fetching image",
                description: error.toString(),
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImageFile(file);
            const reader = new FileReader();
            reader.onload = (event) => {
                const img = new Image();
                img.src = event.target.result;
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    const maxDim = 512;
    
                    canvas.width = maxDim;
                    canvas.height = maxDim;
    
                    // Draw the image on canvas and resize it to 512x512
                    ctx.drawImage(img, 0, 0, maxDim, maxDim);
                    
                    setImagePreview(canvas.toDataURL());
                };
            };
            reader.readAsDataURL(file);
        }
    };    

    const handleColorChange = (name, color) => {
        setEditingDisplayGame({ ...editingDisplayGame, [name]: color.hex });
    };

    const handleSubmitEditImage = async () => {
        if (!imageFile) {
            toast({
                title: "No image selected",
                description: "Please select an image to upload.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }
    
        const img = new Image();
        img.src = imagePreview;
        img.onload = async () => {
            const canvas = document.createElement('canvas');
            canvas.width = 512;
            canvas.height = 512;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, 512, 512);
            canvas.toBlob(async (blob) => {
                const formData = new FormData();
                formData.append('image', blob, 'gameImage.png');
                formData.append('gameName', editingImageGame.gameName);
    
                try {
                    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/upload-game-image`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        },
                    });
    
                    const imageUrl = response.data.url;
    
                    await axios.put(`${process.env.REACT_APP_API_URL}/api/admin/game/edit-image/${editingImageGame.gameId}`, {
                        gameImage: imageUrl
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        },
                    });
    
                    // fetchGamesByProvider(providerId, page);
                    // Update the specific game in the state
                    setGames((prevGames) =>
                        prevGames.map((game) =>
                            game.gameId === editingImageGame.gameId ? { ...game, gameImage: imageUrl } : game
                        )
                    );

                    setFilteredGames((prevFilteredGames) =>
                        prevFilteredGames.map((game) =>
                            game.gameId === editingImageGame.gameId ? { ...game, gameImage: imageUrl } : game
                        )
                    );

                    setIsImageModalOpen(false);
                    toast({
                        title: "Game image updated.",
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                    });
                } catch (err) {
                    const errorMessage = err.response && err.response.data.message ? err.response.data.message : "An unexpected error occurred.";
                    toast({
                        title: "Error updating game image",
                        description: errorMessage,
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                    });
                }
            }, 'image/png');
        };
    }; 

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditingGame({ ...editingGame, [name]: value });
        setEditingDisplayGame({ ...editingDisplayGame, [name]: value });
    };

    const handleSubmitEditDisplay = async () => {
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/api/admin/game/edit-display/${editingDisplayGame.gameId}`, editingDisplayGame, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            // fetchGamesByProvider(providerId, page);
            // Update the specific game in the state
            setGames((prevGames) =>
                prevGames.map((game) =>
                    game.gameId === editingDisplayGame.gameId ? { ...game, ...editingDisplayGame } : game
                )
            );

            setFilteredGames((prevFilteredGames) =>
                prevFilteredGames.map((game) =>
                    game.gameId === editingDisplayGame.gameId ? { ...game, ...editingDisplayGame } : game
                )
            );

            setIsDisplayModalOpen(false);
            toast({
                title: "Game display updated.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (err) {
            const errorMessage = err.response && err.response.data.message ? err.response.data.message : "An unexpected error occurred.";
            toast({
                title: "Error updating game display",
                description: errorMessage,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleActivateDeactivateClick = (game) => {
        setActiveDeactiveGame(game);
        setIsActivateDeactivateModalOpen(true);
    };

    const handleSubmitActivateDeactivateGame = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/game/activate-deactivate/${activeDeactiveGame.gameId}`, {
                isActive: !activeDeactiveGame.isActive
            }, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            // fetchGamesByProvider(providerId, page);
            // Update the specific game in the state
            setGames((prevGames) =>
                prevGames.map((game) =>
                    game.gameId === activeDeactiveGame.gameId ? { ...game, isActive: !game.isActive } : game
                )
            );

            setFilteredGames((prevFilteredGames) =>
                prevFilteredGames.map((game) =>
                    game.gameId === activeDeactiveGame.gameId ? { ...game, isActive: !game.isActive } : game
                )
            );

            setIsActivateDeactivateModalOpen(false);
            toast({
                title: `Game ${activeDeactiveGame.isActive ? "deactivated" : "activated"}.`,
                description: response.data.message,
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (err) {
            const errorMessage = err.response && err.response.data.message ? err.response.data.message : "An unexpected error occurred.";
            toast({
                title: "Error activating/deactivating game",
                description: errorMessage,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleNewGameToggle = (game) => {
        setToggleNewGame(game);
        setIsToggleNewGameModalOpen(true);
    };

    const handleSubmitToggleNewGame = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/game/toggle-new/${toggleNewGame.gameId}`, {
                isNewGame: !toggleNewGame.isNewGame
            }, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            // fetchGamesByProvider(providerId, page);
            // Update the specific game in the state
            setGames((prevGames) =>
                prevGames.map((game) =>
                    game.gameId === toggleNewGame.gameId ? { ...game, isNewGame: !game.isNewGame } : game
                )
            );

            setFilteredGames((prevFilteredGames) =>
                prevFilteredGames.map((game) =>
                    game.gameId === toggleNewGame.gameId ? { ...game, isNewGame: !game.isNewGame } : game
                )
            );

            setIsToggleNewGameModalOpen(false);
            toast({
                title: `Game ${!toggleNewGame.isNewGame ? "marked as new" : "removed from new"}.`,
                description: response.data.message,
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (err) {
            const errorMessage = err.response && err.response.data.message ? err.response.data.message : "An unexpected error occurred.";
            toast({
                title: "Error updating game new status",
                description: errorMessage,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleRankingChange = (game) => {
        setEditingGame(game);
        setIsRankingModalOpen(true);
    };

    const handleSubmitEditRanking = async () => {
        const updatedRankingData = {
            ranking: editingGame.ranking
        };
    
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/api/admin/game/edit-ranking/${editingGame.gameId}`, updatedRankingData, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            // fetchGamesByProvider(providerId, page);
            // Update the specific game in the state
            setGames((prevGames) =>
                prevGames.map((game) =>
                    game.gameId === editingGame.gameId ? { ...game, ranking: editingGame.ranking } : game
                )
            );

            setFilteredGames((prevFilteredGames) =>
                prevFilteredGames.map((game) =>
                    game.gameId === editingGame.gameId ? { ...game, ranking: editingGame.ranking } : game
                )
            );

            setIsRankingModalOpen(false);
            toast({
                title: "Game ranking updated.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (err) {
            const errorMessage = err.response && err.response.data.message ? err.response.data.message : "An unexpected error occurred.";
            toast({
                title: "Error updating game ranking",
                description: errorMessage,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleCategoryChange = (game) => {
        setEditingGame({ gameId: game.gameId, categoryId: game.categoryId });
        setIsCategoryChangeModalOpen(true);
    };

    const handleCategorySelectChange = (e) => {
        setEditingGame({ ...editingGame, categoryId: e.target.value });
    };

    const handleSubmitEditCategory = async () => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/admin/game/edit-category/${editingGame.gameId}`, {
                categoryId: editingGame.categoryId
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });

            // Find the category name based on the selected categoryId
            const updatedCategoryName = categories.find(cat => cat.categoryId === parseInt(editingGame.categoryId))?.categoryName;

            // fetchGamesByProvider(providerId, page);
            // Update the specific game in the state with both categoryId and categoryName
            setGames((prevGames) =>
                prevGames.map((game) =>
                    game.gameId === editingGame.gameId
                        ? { ...game, categoryId: editingGame.categoryId, categoryName: updatedCategoryName }
                        : game
                )
            );

            setFilteredGames((prevFilteredGames) =>
                prevFilteredGames.map((game) =>
                    game.gameId === editingGame.gameId
                        ? { ...game, categoryId: editingGame.categoryId, categoryName: updatedCategoryName }
                        : game
                )
            );

            setIsCategoryChangeModalOpen(false);
            toast({
                title: "Category updated successfully",
                description: response.data.message,
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (err) {
            const errorMessage = err.response && err.response.data.message ? err.response.data.message : "An unexpected error occurred.";
            toast({
                title: "Error updating category",
                description: errorMessage,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };    

    const convertRGBStringToHex = (rgbString) => {
        // Extract numbers from the rgb string
        const result = rgbString.match(/\d+/g);  // This extracts the numbers
        if (result && result.length === 3) {
            const r = parseInt(result[0], 10);
            const g = parseInt(result[1], 10);
            const b = parseInt(result[2], 10);
            // Convert to hex and return
            return (
                "#" +
                ((1 << 24) + (r << 16) + (g << 8) + b)
                    .toString(16)
                    .slice(1)
                    .toUpperCase()
            );
        }
        return null;  // Return null if the input is invalid
    };    

    const handleEditGameNameClick = (game) => {
        setEditingGame(game);
        setEditingGameName(game.gameName);
        setIsGameNameModalOpen(true);
    };

    const handleGameNameInputChange = (e) => {
        setEditingGameName(e.target.value);
    };

    const handleSubmitEditGameName = async () => {
        const updatedNameData = {
            gameName: editingGameName
        };
    
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/api/admin/game/edit-name/${editingGame.gameId}`, updatedNameData, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            // fetchGamesByProvider(providerId, page);
            // Update the specific game in the state
            setGames((prevGames) =>
                prevGames.map((game) =>
                    game.gameId === editingGame.gameId ? { ...game, gameName: editingGameName } : game
                )
            );

            setFilteredGames((prevFilteredGames) =>
                prevFilteredGames.map((game) =>
                    game.gameId === editingGame.gameId ? { ...game, gameName: editingGameName } : game
                )
            );
            setIsGameNameModalOpen(false);
            toast({
                title: "Game name updated.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (err) {
            const errorMessage = err.response && err.response.data.message ? err.response.data.message : "An unexpected error occurred.";
            toast({
                title: "Error updating game name",
                description: errorMessage,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleEditRtpClick = (game) => {
        setEditingGame(game);
        setEditingRtpValue(game.rtpValue); // Load current RTP value
        setIsRtpModalOpen(true);
    };

    const handleRtpInputChange = (e) => {
        setEditingRtpValue(e.target.value);
    };
    
    const handleSubmitEditRtp = async () => {
        const updatedRtpData = {
            rtpValue: parseFloat(editingRtpValue)
        };
    
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/api/admin/game/edit-rtp/${editingGame.gameId}`, updatedRtpData, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            // fetchGamesByProvider(providerId, page);
            // Update the specific game in the state
            setGames((prevGames) =>
                prevGames.map((game) =>
                    game.gameId === editingGame.gameId ? { ...game, rtpValue: parseFloat(editingRtpValue) } : game
                )
            );

            setFilteredGames((prevFilteredGames) =>
                prevFilteredGames.map((game) =>
                    game.gameId === editingGame.gameId ? { ...game, rtpValue: parseFloat(editingRtpValue) } : game
                )
            );
    
            setIsRtpModalOpen(false);
            toast({
                title: "RTP value updated.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (err) {
            const errorMessage = err.response && err.response.data.message ? err.response.data.message : "An unexpected error occurred.";
            toast({
                title: "Error updating RTP value",
                description: errorMessage,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };    
    
    const currentPageGames = filteredGames.slice((page - 1) * 20, page * 20);
    const totalPages = Math.ceil(filteredGames.length / 20);

    return (
        <ChakraProvider>
            <Box className="App" p={4}>
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={4}>
                    <Box fontSize="2xl" fontWeight="bold" flex="1">
                        {providerName}
                    </Box>
                    <Button width="10%" colorScheme="blue" size="sm" onClick={() => navigate('/gameProviders')}>
                        Back
                    </Button>
                </Box>
                {loading ? (
                    <Spinner size="xl" />
                ) : (
                    <>
                        {/*Search Bar*/}
                        <Box mb={4}>
                            <Input
                                type="text"
                                placeholder="Search games..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                mb={4}
                            />
                        </Box>

                        {/* FILTER CONTROLS HERE */}
                        <Box mb={4} display="flex" justifyContent="space-between" alignItems="center">
                            <Box width="30%">
                                <Select placeholder="Filter by Category" onChange={(e) => setSelectedCategory(e.target.value)}>
                                    {categories.map((category) => (
                                        <option key={category.categoryId} value={category.categoryId}>
                                            {category.categoryName}
                                        </option>
                                    ))}
                                </Select>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <FormLabel htmlFor="isActiveFilter" mb="0">
                                    Active Only
                                </FormLabel>
                                <Switch id="isActiveFilter" onChange={(e) => setIsActiveFilter(e.target.checked)} />
                            </Box>
                            <Box display="flex" alignItems="center" ml={4}>
                                <FormLabel htmlFor="isNewGameFilter" mb="0">
                                    New Games Only
                                </FormLabel>
                                <Switch id="isNewGameFilter" onChange={(e) => setIsNewGameFilter(e.target.checked)} />
                            </Box>
                        </Box>
                        <Box className="scrollable-container">
                            <Table variant="striped" colorScheme="gray">
                                <Thead>
                                    <Tr>
                                        <Th>Game Code</Th>
                                        <Th>Game Name</Th>
                                        <Th>Category</Th>
                                        <Th>Game Image</Th>
                                        <Th>Ranking</Th>
                                        <Th>Border Color</Th>
                                        <Th>Font Color</Th>
                                        <Th>Rtp Value</Th>
                                        <Th>Is New Game</Th>
                                        <Th>Is Active</Th>
                                        <Th>Actions</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {currentPageGames.map((game) => (
                                        <Tr key={game.gameId}>
                                            <Td>{game.gameCode}</Td>
                                            <Td>
                                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                                    <span>{game.gameName}</span>
                                                    <IconButton
                                                        icon={<EditIcon />}
                                                        size="sm"
                                                        ml={2}
                                                        onClick={() => handleEditGameNameClick(game)}
                                                    />
                                                </Box>
                                            </Td>
                                            <Td>
                                                {isCategoryChangeModalOpen && editCategory?.gameId === game.gameId ? (
                                                    <Select
                                                        value={editingGame.categoryId}
                                                        onChange={handleCategorySelectChange}
                                                    >
                                                        {categories.map((category) => (
                                                            <option key={category.categoryId} value={category.categoryId}>
                                                                {category.categoryName}
                                                            </option>
                                                        ))}
                                                    </Select>
                                                ) : (
                                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                                        <span>{game.categoryName}</span>
                                                        <IconButton
                                                            icon={<EditIcon />}
                                                            size="sm"
                                                            ml={2}
                                                            onClick={() => handleCategoryChange(game)}
                                                        />
                                                    </Box>
                                                )}
                                            </Td>
                                            <Td>
                                                <img src={game.gameImage} alt={game.gameName} style={{ width: '50px', height: '50px' }} />
                                            </Td>
                                            <Td>
                                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                                    <span>{game.ranking}</span>
                                                    <IconButton
                                                        icon={<EditIcon />}
                                                        size="sm"
                                                        ml={2}
                                                        onClick={() => handleRankingChange(game)}
                                                    />
                                                </Box>
                                            </Td>
                                            <Td>
                                                <Box
                                                    w="20px"
                                                    h="20px"
                                                    borderRadius="50%"
                                                    bgColor={game.borderColor}
                                                    border="1px solid black"
                                                />
                                            </Td>
                                            <Td>
                                                <Box
                                                    w="20px"
                                                    h="20px"
                                                    borderRadius="50%"
                                                    bgColor={game.fontColor}
                                                    border="1px solid black"
                                                />
                                            </Td>
                                            <Td>
                                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                                    <span>{game.rtpValue}%</span>
                                                    <IconButton
                                                        icon={<EditIcon />}
                                                        size="sm"
                                                        ml={2}
                                                        onClick={() => handleEditRtpClick(game)}
                                                    />
                                                </Box>
                                            </Td>
                                            <Td>
                                                <Switch
                                                    isChecked={game.isNewGame}
                                                    onChange={() => handleNewGameToggle(game)}
                                                />
                                            </Td>
                                            <Td>
                                                <Switch
                                                    isChecked={game.isActive}
                                                    onChange={() => handleActivateDeactivateClick(game)}
                                                />
                                            </Td>
                                            <Td>
                                                <Button size="sm" colorScheme="blue" onClick={() => handleEditImageClick(game)} mr={2}>
                                                    Edit Image
                                                </Button>
                                                <Button size="sm" colorScheme="blue" onClick={() => handleEditDisplayClick(game)}>
                                                    Edit Display
                                                </Button>
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
                            <Button onClick={() => setPage(1)} disabled={page === 1} mr={2}>
                                First
                            </Button>
                            <Button onClick={() => setPage(page - 1)} disabled={page === 1} mr={2}>
                                Previous
                            </Button>
                            {Array.from({ length: totalPages }, (_, i) => i + 1).slice(Math.max(0, page - 5), Math.min(totalPages, page + 4)).map((pageNumber) => (
                                <Button
                                    key={pageNumber}
                                    onClick={() => setPage(pageNumber)}
                                    colorScheme={pageNumber === page ? 'blue' : 'gray'}
                                    mr={1}
                                >
                                    {pageNumber}
                                </Button>
                            ))}
                            <Button onClick={() => setPage(page + 1)} disabled={page === totalPages} mr={2}>
                                Next
                            </Button>
                            <Button onClick={() => setPage(totalPages)} disabled={page === totalPages}>
                                Last
                            </Button>
                        </Box>
                    </>
                )}

                {/* Edit Game Image Modal */}
                <Modal isOpen={isImageModalOpen} onClose={() => setIsImageModalOpen(false)} size="xl">
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Edit Game Image (ID: {editingImageGame?.gameId})</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            <FormControl>
                                <img src={imagePreview} alt={editingImageGame?.gameName} className="fixed-dimension" />
                                <FormLabel>Game Image</FormLabel>
                                <Input
                                    type="file"
                                    accept="image/png, image/jpeg, image/jpg"
                                    onChange={handleImageChange}
                                />
                            </FormControl>
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={handleSubmitEditImage}>
                                Save Changes
                            </Button>
                            <Button onClick={() => setIsImageModalOpen(false)}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {/* Edit Game Display Modal */}
                <Modal isOpen={isDisplayModalOpen} onClose={() => setIsDisplayModalOpen(false)} size="2xl">
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Edit Game Display (ID: {editingDisplayGame?.gameId})</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6} display="flex" flexDirection="column" alignItems="center">

                            {/* Display Game Image with Color Picker */}
                            {editingDisplayGame?.gameImage && (
                                <FormControl mb={4}>
                                    <FormLabel>Pick Color from Image</FormLabel>
                                    <ImageColorPicker
                                        imgSrc={imageSrc}  // The game's image
                                        onColorPick={(color) => {
                                            const hexColor = convertRGBStringToHex(color);
                                            setPickedColor(hexColor); 
                                            setEditingDisplayGame({ ...editingDisplayGame, borderColor: hexColor }); // Update border color
                                        }}         
                                        pickerSize={20}                     // Size of the picker dot
                                    />
                                </FormControl>
                            )}

                            <FormControl flex="1" mb={4}>
                                <FormLabel>Border Color</FormLabel>
                                    <Input
                                        name="borderColor"
                                        type="text"
                                        value={editingDisplayGame?.borderColor}
                                        onChange={handleInputChange}
                                    />
                            </FormControl>

                            {/* Display Selected Color Hex Code */}
                            {/* <FormControl mb={4}>
                                <FormLabel>Selected Color Hex Code</FormLabel>
                                <Input
                                    value={pickedColor}  // Show the picked color hex code
                                    isReadOnly           // Make it read-only
                                />
                            </FormControl> */}

                            {/* Existing SketchPicker for Border Color */}
                            {/* <FormControl flex="1" mb={4}>
                                <FormLabel>Border Color</FormLabel>
                                <Input
                                    name="borderColor"
                                    type="text"
                                    value={editingDisplayGame?.borderColor}
                                    onChange={handleInputChange}
                                />
                                <SketchPicker
                                    color={editingDisplayGame?.borderColor}
                                    onChangeComplete={(color) => handleColorChange('borderColor', color)}
                                />
                            </FormControl> */}

                            {/* Existing SketchPicker for Font Color */}
                            {/* <FormControl flex="1">
                                <FormLabel>Font Color</FormLabel>
                                <Input
                                    name="fontColor"
                                    type="text"
                                    value={editingDisplayGame?.fontColor}
                                    onChange={handleInputChange}
                                />
                                <SketchPicker
                                    color={editingDisplayGame?.fontColor}
                                    onChangeComplete={(color) => handleColorChange('fontColor', color)}
                                />
                            </FormControl> */}
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={handleSubmitEditDisplay}>
                                Save Changes
                            </Button>
                            <Button onClick={() => setIsDisplayModalOpen(false)}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {/* Activate/Deactivate Game Modal */}
                <Modal isOpen={isActivateDeactivateModalOpen} onClose={() => setIsActivateDeactivateModalOpen(false)}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>{activeDeactiveGame?.isActive ? "Deactivate" : "Activate"} Game</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            Are you sure you want to {activeDeactiveGame?.isActive ? "deactivate" : "activate"} this game?
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={handleSubmitActivateDeactivateGame}>
                                Yes
                            </Button>
                            <Button onClick={() => setIsActivateDeactivateModalOpen(false)}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {/* Toggle New Game Modal */}
                <Modal isOpen={isToggleNewGameModalOpen} onClose={() => setIsToggleNewGameModalOpen(false)}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Toggle New Game (ID: {toggleNewGame?.gameId})</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            Are you sure you want to {toggleNewGame?.isNewGame ? "remove this game from new" : "mark this game as new"}?
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={handleSubmitToggleNewGame}>
                                Yes
                            </Button>
                            <Button onClick={() => setIsToggleNewGameModalOpen(false)}>No</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {/* Edit Game Ranking Modal */}
                <Modal isOpen={isRankingModalOpen} onClose={() => setIsRankingModalOpen(false)}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Edit Game Ranking (ID: {editingGame?.gameId})</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            <FormControl>
                                <FormLabel>Game Ranking</FormLabel>
                                <Input
                                    name="ranking"
                                    type="number"
                                    value={editingGame?.ranking}
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={handleSubmitEditRanking}>
                                Save Changes
                            </Button>
                            <Button onClick={() => setIsRankingModalOpen(false)}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {/* Edit Game Name Modal */}
                <Modal isOpen={isGameNameModalOpen} onClose={() => setIsGameNameModalOpen(false)}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Edit Game Name (ID: {editingGame?.gameId})</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            <FormControl>
                                <FormLabel>Game Name</FormLabel>
                                <Input
                                    name="gameName"
                                    type="text"
                                    value={editingGameName}
                                    onChange={handleGameNameInputChange}
                                />
                            </FormControl>
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={handleSubmitEditGameName}>
                                Save Changes
                            </Button>
                            <Button onClick={() => setIsGameNameModalOpen(false)}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {/* Edit RTP Value Modal */}
                <Modal isOpen={isRtpModalOpen} onClose={() => setIsRtpModalOpen(false)}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Edit RTP Value (ID: {editingGame?.gameId})</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            <FormControl>
                                <FormLabel>RTP Value (%)</FormLabel>
                                <Input
                                    name="rtpValue"
                                    type="number"
                                    step="0.01"
                                    value={editingRtpValue}
                                    onChange={handleRtpInputChange}
                                />
                            </FormControl>
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={handleSubmitEditRtp}>
                                Save Changes
                            </Button>
                            <Button onClick={() => setIsRtpModalOpen(false)}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {/* Edit Game Category Modal */}
                <Modal isOpen={isCategoryChangeModalOpen} onClose={() => setIsCategoryChangeModalOpen(false)}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Edit Game Category (ID: {editingGame?.gameId})</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            <FormControl>
                                <FormLabel>Game Category</FormLabel>
                                <Select
                                    value={editingGame.categoryId}
                                    onChange={handleCategorySelectChange}
                                >
                                    {categories.map((category) => (
                                        <option key={category.categoryId} value={category.categoryId}>
                                            {category.categoryName}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={handleSubmitEditCategory}>
                                Save Changes
                            </Button>
                            <Button onClick={() => setIsCategoryChangeModalOpen(false)}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

            </Box>
        </ChakraProvider>
    );
}

export default GamesByProvider;