import React, { useState, useEffect } from 'react';
import { ChakraProvider, Input, useToast, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, FormControl, FormLabel, Switch, Box, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './SideNavigationMenu.css';

function SideNavigationMenu() {
    const [sideMenus, setSideMenus] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isActivateDeactivateModalOpen, setIsActivateDeactivateModalOpen] = useState(false);
    const [activeDeactiveMenu, setActiveDeactiveMenu] = useState(null);
    const toast = useToast();

    useEffect(() => {
        fetchSideMenus();
    }, []);

    const fetchSideMenus = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/retrieve-side-menus`, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            if (response.data.success) {
                setSideMenus(response.data.data);
            } else {
                toast({
                    title: "Failed to fetch side menus.",
                    description: response.data.message,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        } catch (err) {
            toast({
                title: "Error fetching side menus",
                description: err.toString(),
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleActivateDeactivateClick = (menu) => {
        setActiveDeactiveMenu(menu);
        setIsActivateDeactivateModalOpen(true);
    };

    const handleSubmitActivateDeactivateMenu = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/side-menu/activate-deactivate/${activeDeactiveMenu.sideMenuId}`, {
                isActive: !activeDeactiveMenu.isActive
            }, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            fetchSideMenus();
            setIsActivateDeactivateModalOpen(false);
            toast({
                title: `Side menu ${activeDeactiveMenu.isActive ? "deactivated" : "activated"}.`,
                description: response.data.message,
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (err) {
            const errorMessage = err.response && err.response.data.message ? err.response.data.message : "An unexpected error occurred.";
            toast({
                title: "Error activating/deactivating side menu",
                description: errorMessage,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleOnDragEnd = async (result) => {
        if (!result.destination) return;

        const reorderedMenus = Array.from(sideMenus);
        const [reorderedItem] = reorderedMenus.splice(result.source.index, 1);
        reorderedMenus.splice(result.destination.index, 0, reorderedItem);

        setSideMenus(reorderedMenus);

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/side-menu/update-rankings`, {
                menus: reorderedMenus.map((menu, index) => ({ sideMenuId: menu.sideMenuId, ranking: index }))
            }, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            toast({
                title: "Side menu rankings updated.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (err) {
            toast({
                title: "Error updating rankings",
                description: err.toString(),
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    return (
        <ChakraProvider>
            <Box className="App" p={4}>
                {/* <Box className="search-bar-container" mb={4}>
                    <Input
                        type="text"
                        placeholder="Search side menu..."
                        onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
                    />
                </Box> */}

                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="sideMenus">
                        {(provided) => (
                            <Table {...provided.droppableProps} ref={provided.innerRef} variant="striped" colorScheme="gray">
                                <Thead>
                                    <Tr>
                                        <Th>Menu Name</Th>
                                        <Th>Ranking</Th>
                                        <Th>Is Active</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {sideMenus
                                        .filter((menu) => menu.sideMenuName.toLowerCase().includes(searchQuery))
                                        .map((menu, index) => (
                                            <Draggable key={menu.sideMenuId} draggableId={menu.sideMenuId.toString()} index={index}>
                                                {(provided) => (
                                                    <Tr {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                        <Td>{menu.sideMenuName}</Td>
                                                        <Td>{index + 1}</Td>
                                                        <Td>
                                                            <Switch
                                                                isChecked={menu.isActive}
                                                                onChange={() => handleActivateDeactivateClick(menu)}
                                                            />
                                                        </Td>
                                                    </Tr>
                                                )}
                                            </Draggable>
                                        ))}
                                    {provided.placeholder}
                                </Tbody>
                            </Table>
                        )}
                    </Droppable>
                </DragDropContext>

                {/* Activate/Deactivate Side Menu Modal */}
                <Modal isOpen={isActivateDeactivateModalOpen} onClose={() => setIsActivateDeactivateModalOpen(false)}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>{activeDeactiveMenu?.isActive ? "Deactivate" : "Activate"} Side Menu</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            Are you sure you want to {activeDeactiveMenu?.isActive ? "deactivate" : "activate"} this side menu?
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={handleSubmitActivateDeactivateMenu}>
                                Yes
                            </Button>
                            <Button onClick={() => setIsActivateDeactivateModalOpen(false)}>No</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Box>
        </ChakraProvider>
    );
}

export default SideNavigationMenu;
