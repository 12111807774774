import React, { useState, useEffect } from 'react';
import { ChakraProvider, Input, useToast, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, FormControl, FormLabel, Switch, Box, Table, Thead, Tbody, Tr, Th, Td, IconButton } from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './GameProviders.css';

function GameProviders() {
    const [gameProviders, setGameProviders] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isRankingModalOpen, setIsRankingModalOpen] = useState(false);
    const [isActivateDeactivateModalOpen, setIsActivateDeactivateModalOpen] = useState(false);
    const [isProviderModalOpen, setIsProviderModalOpen] = useState(false);
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [activeDeactiveProvider, setActiveDeactiveProvider] = useState(null);
    const [editingImageProvider, setEditingImageProvider] = useState(null);
    const [imagePreview, setImagePreview] = useState('');
    const [imageFile, setImageFile] = useState(null);
    const toast = useToast();

    const [editingProvider, setEditingProvider] = useState({
        providerId: '',
        providerName: '',
        providerDetails: '',
        providerRanking: 0,
        providerImage: ''
    });

    useEffect(() => {
        fetchGameProviders();
    }, []);

    const fetchGameProviders = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/retrieve-game-providers`, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            if (response.data.success) {
                setGameProviders(response.data.data);
            } else {
                toast({
                    title: "Failed to fetch game providers.",
                    description: response.data.message,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        } catch (err) {
            toast({
                title: "Error fetching game providers",
                description: err.toString(),
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const validateProvider = (provider) => {
        if (!provider.providerName.trim()) {
            toast({ title: "Provider name is required", status: "error", duration: 5000, isClosable: true });
            return false;
        }
        return true;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditingProvider({ ...editingProvider, [name]: value });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImageFile(file);
            const reader = new FileReader();
            reader.onload = (event) => {
                const img = new Image();
                img.src = event.target.result;
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    const maxDim = 512;
    
                    canvas.width = maxDim;
                    canvas.height = maxDim;
    
                    // Draw the image on canvas and resize it to 512x512
                    ctx.drawImage(img, 0, 0, maxDim, maxDim);
                    
                    setImagePreview(canvas.toDataURL());
                };
            };
            reader.readAsDataURL(file);
        }
    };

    const handleRankingChange = (provider) => {
        setEditingProvider(provider);
        setIsRankingModalOpen(true);
    };

    const handleSubmitEditProviderDetails = async () => {
        const updatedProviderData = {
            providerName: editingProvider.providerName,
        };
    
        if (!validateProvider(updatedProviderData)) return;
    
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/api/admin/game-provider/edit-details/${editingProvider.providerId}`, updatedProviderData, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            fetchGameProviders();
            setIsProviderModalOpen(false);
            toast({
                title: "Provider name updated.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (err) {
            const errorMessage = err.response && err.response.data.message ? err.response.data.message : "An unexpected error occurred.";
            toast({
                title: "Error updating provider name",
                description: errorMessage,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleSubmitEditProviderRanking = async () => {
        const updatedProviderData = {
            providerRanking: editingProvider.providerRanking
        };
    
        if (updatedProviderData.providerRanking === undefined) {
            toast({
                title: "Provider ranking is required",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }
    
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/api/admin/game-provider/edit-ranking/${editingProvider.providerId}`, updatedProviderData, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            fetchGameProviders();
            setIsRankingModalOpen(false);
            toast({
                title: "Provider ranking updated.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (err) {
            const errorMessage = err.response && err.response.data.message ? err.response.data.message : "An unexpected error occurred.";
            toast({
                title: "Error updating provider ranking",
                description: errorMessage,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleSubmitEditImage = async () => {
        if (!imageFile) {
            toast({
                title: "No image selected",
                description: "Please select an image to upload.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        const img = new Image();
        img.src = imagePreview;
        img.onload = async () => {
            const canvas = document.createElement('canvas');
            canvas.width = 512;
            canvas.height = 512;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, 512, 512);
            canvas.toBlob(async (blob) => {
                const formData = new FormData();
                formData.append('image', blob, 'providerImage.png');
                formData.append('providerName', editingImageProvider.providerName);

                try {
                    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/upload-image`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        },
                    });

                    const imageUrl = response.data.url;

                    await axios.put(`${process.env.REACT_APP_API_URL}/api/admin/game-provider/edit-image/${editingImageProvider.providerId}`, {
                        providerImage: imageUrl
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        },
                    });

                    fetchGameProviders();
                    setIsImageModalOpen(false);
                    toast({
                        title: "Provider image updated.",
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                    });
                } catch (err) {
                    const errorMessage = err.response && err.response.data.message ? err.response.data.message : "An unexpected error occurred.";
                    toast({
                        title: "Error updating provider image",
                        description: errorMessage,
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                    });
                }
            }, 'image/png');
        };
    };

    const handleActivateDeactivateClick = (provider) => {
        setActiveDeactiveProvider(provider);
        setIsActivateDeactivateModalOpen(true);
    };

    const handleEditImageClick = (provider) => {
        setEditingImageProvider(provider);
        setImagePreview(provider.providerImage);
        setIsImageModalOpen(true);
    };

    const handleEditProviderClick = (provider) => {
        setEditingProvider(provider);
        setIsProviderModalOpen(true);
    };

    const handleSubmitActivateDeactivateProvider = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/game-provider/activate-deactivate/${activeDeactiveProvider.providerId}`, {
                isActive: !activeDeactiveProvider.isActive
            }, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            fetchGameProviders();
            setIsActivateDeactivateModalOpen(false);
            toast({
                title: `Provider ${activeDeactiveProvider.isActive ? "deactivated" : "activated"}.`,
                description: response.data.message,
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (err) {
            const errorMessage = err.response && err.response.data.message ? err.response.data.message : "An unexpected error occurred.";
            toast({
                title: "Error activating/deactivating provider",
                description: errorMessage,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <ChakraProvider>
            <Box className="App" p={4}>
                <Box className="search-bar-container" mb={4}>
                    <Input
                        type="text"
                        placeholder="Search game provider..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
                    />
                </Box>

                <Table variant="striped" colorScheme="gray">
                    <Thead>
                        <Tr>
                            <Th>Provider ID</Th>
                            <Th>Provider Name</Th>
                            <Th>Provider Details</Th>
                            <Th>Provider Ranking</Th>
                            <Th>Provider Image</Th>
                            <Th>Is Active</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {gameProviders
                            .filter((provider) => provider.providerName.toLowerCase().includes(searchQuery))
                            .map((provider) => (
                                <Tr key={provider.providerId}>
                                    <Td>{provider.providerId}</Td>
                                    <Td>
                                        <Link to={`/games-by-provider/${provider.providerId}`} state={{ providerName: provider.providerName }} style={{ cursor: 'pointer', color: 'blue' }}>
                                            {provider.providerName}
                                        </Link>
                                    </Td>
                                    <Td>{provider.providerDetails}</Td>
                                    <Td>
                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                            <span>{provider.providerRanking}</span>
                                            <IconButton
                                                icon={<EditIcon />}
                                                size="sm"
                                                ml={2}
                                                onClick={() => handleRankingChange(provider)}
                                            />
                                        </Box>
                                    </Td>
                                    <Td>
                                        <img src={provider.providerImage} alt={provider.providerName} style={{ width: '50px', height: '50px' }} />
                                    </Td>
                                    <Td>
                                        <Switch
                                            isChecked={provider.isActive}
                                            onChange={() => handleActivateDeactivateClick(provider)}
                                        />
                                    </Td>
                                    <Td>
                                        <Button size="sm" colorScheme="blue" onClick={() => handleEditImageClick(provider)} mr={2}>
                                            Edit Image
                                        </Button>
                                        <Button size="sm" colorScheme="blue" onClick={() => handleEditProviderClick(provider)} mr={2}>
                                            Edit Provider Name
                                        </Button>
                                    </Td>
                                </Tr>
                            ))}
                    </Tbody>
                </Table>

                {/* Edit Provider Ranking Modal */}
                <Modal isOpen={isRankingModalOpen} onClose={() => setIsRankingModalOpen(false)}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Edit Provider Ranking (ID: {editingProvider?.providerId})</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            <FormControl>
                                <FormLabel>Provider Ranking</FormLabel>
                                <Input
                                    name="providerRanking"
                                    type="number"
                                    value={editingProvider?.providerRanking}
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={handleSubmitEditProviderRanking}>
                                Save Changes
                            </Button>
                            <Button onClick={() => setIsRankingModalOpen(false)}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {/* Edit Provider Image Modal */}
                <Modal isOpen={isImageModalOpen} onClose={() => setIsImageModalOpen(false)} size="xl">
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Edit Provider Image (ID: {editingImageProvider?.providerId})</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            <FormControl>
                                <img src={imagePreview} alt={editingImageProvider?.providerName} className="fixed-dimension" />
                                <FormLabel>Provider Image</FormLabel>
                                <Input
                                    type="file"
                                    accept="image/png, image/jpeg, image/jpg"
                                    onChange={handleImageChange}
                                />
                            </FormControl>
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={handleSubmitEditImage}>
                                Save Changes
                            </Button>
                            <Button onClick={() => setIsImageModalOpen(false)}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {/* Activate/Deactivate Provider Modal */}
                <Modal isOpen={isActivateDeactivateModalOpen} onClose={() => setIsActivateDeactivateModalOpen(false)}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>{activeDeactiveProvider?.isActive ? "Deactivate" : "Activate"} Provider</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            Are you sure you want to {activeDeactiveProvider?.isActive ? "deactivate" : "activate"} this provider?
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={handleSubmitActivateDeactivateProvider}>
                                Yes
                            </Button>
                            <Button onClick={() => setIsActivateDeactivateModalOpen(false)}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {/* Edit Provider Details Modal */}
                <Modal isOpen={isProviderModalOpen} onClose={() => setIsProviderModalOpen(false)}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Edit Provider (ID: {editingProvider?.providerId})</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            <FormControl>
                                <FormLabel>Provider Name</FormLabel>
                                <Input
                                    name="providerName"
                                    type="text"
                                    value={editingProvider?.providerName}
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={handleSubmitEditProviderDetails}>
                                Save Changes
                            </Button>
                            <Button onClick={() => setIsProviderModalOpen(false)}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Box>
        </ChakraProvider>
    );
}

export default GameProviders;
