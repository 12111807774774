import React, { useState, useEffect } from 'react';
import { ChakraProvider, Box, Table, Thead, Tbody, Tr, Th, Td, useToast, Button } from '@chakra-ui/react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

function AffiliateChild() {
    const { userId } = useParams(); // Get userId from URL parameters
    const navigate = useNavigate();
    const location = useLocation();
    const { userName } = location.state; // Get providerName from state
    const [downlines, setDownlines] = useState([]);
    const [loading, setLoading] = useState(false);
    const toast = useToast();

    useEffect(() => {
        fetchDownlines(userId);
    }, [userId]);

    const fetchDownlines = async (userId) => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/retrieve-downlines/${userId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            if (response.data.success) {
                setDownlines(response.data.data);
            } else {
                toast({
                    title: "Failed to fetch downlines.",
                    description: response.data.message,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        } catch (err) {
            toast({
                title: "Error fetching downlines",
                description: err.toString(),
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    // Calculate the total turnover and total win/loss
    const totalTurnover = downlines.reduce((sum, downline) => sum + parseFloat(downline.totalTurnover), 0);
    const totalWinLoss = downlines.reduce((sum, downline) => sum + parseFloat(downline.totalWinLoss), 0);

    return (
        <ChakraProvider>
            <Box className="App" p={4}>
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={4}>
                    <Box fontSize="2xl" fontWeight="bold">Downlines of {userName}</Box>
                    <Button width="10%" colorScheme="blue" size="sm" onClick={() => navigate('/affiliate')}>
                        Back
                    </Button>
                </Box>

                <Table variant="striped" colorScheme="gray">
                    <Thead>
                        <Tr>
                            <Th>User ID</Th>
                            <Th>Username</Th>
                            <Th>Email</Th>
                            <Th>Total Turnover</Th>
                            <Th>Total Win/Loss</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {downlines.length === 0 ? (
                            <Tr>
                                <Td colSpan={6} textAlign="center">
                                    {loading ? "Loading..." : "No downlines found."}
                                </Td>
                            </Tr>
                        ) : (
                            downlines.map((downline) => (
                                <Tr key={downline.userId}>
                                    <Td>{downline.userId}</Td>
                                    <Td>{downline.username}</Td>
                                    <Td>{downline.email}</Td>
                                    <Td>{downline.totalTurnover}</Td>
                                    <Td>{downline.totalWinLoss}</Td>
                                </Tr>
                            ))
                        )}
                        {/* Summary row */}
                        <Tr fontWeight="bold">
                            <Td colSpan={3} textAlign="right">Summary:</Td>
                            <Td>{totalTurnover}</Td>
                            <Td>{totalWinLoss}</Td>
                        </Tr>
                    </Tbody>
                </Table>
            </Box>
        </ChakraProvider>
    );
}

export default AffiliateChild;
