// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext'; // import AuthContext
import UserDashboard from './UserDashboard';
import QuestManagement from './QuestManagement';
import Login from './Login';
import Sidebar from './Sidebar';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import Currency from './Currency';
import Report from './Report';
import Maintenance from './Maintenance';
import Threshold from './Threshold';
import GameProviders from './GameProviders';
import SideNavigationMenu from './SideNavigationMenu';
import HomeCategoryOrder from './HomeCategoryOrder';
import HomeListingOrder from './HomeListingOrder';
import GamesByProvider from './GamesByProvider';
import HomeBanner from './HomeBanner';
import Affiliate from './Affiliate';
import AffiliateReport from './AffiliateReport';
import AffiliateChild from './AffiliateChild';
import Games from './Games';
import './App.css';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    return sessionStorage.getItem('isLoggedIn') === 'true';
  });
  const { authState, logout } = useAuth();

  const handleLogout = () => {
    setIsLoggedIn(false);
    sessionStorage.setItem('isLoggedIn', 'false');
    localStorage.removeItem('adminId');
  };

  return (
    <Router>
      <div className="app">
        {authState ? ( // Use authState to determine if logged in
          <>
            <Sidebar handleLogout={handleLogout} />
            <div className="content">
              <Routes>
                <Route path="/users" element={<UserDashboard />} />
                <Route path="/quests" element={<QuestManagement />} />
                <Route path="/currency" element={<Currency />} />
                <Route path="/gameProviders" element={<GameProviders />} />
                <Route path="/games-by-provider/:providerId" element={<GamesByProvider />} />
                <Route path="/allGames" element={<Games />} />
                <Route path="/sideNavigationMenu" element={<SideNavigationMenu />} />
                <Route path="/homeCategoryOrder" element={<HomeCategoryOrder />} />
                <Route path="/homeListingOrder" element={<HomeListingOrder />} />
                <Route path="/homeBanner" element={<HomeBanner />} />
                <Route path="/report" element={<Report />} />
                <Route path="/maintenance" element={<Maintenance />} />
                <Route path="/threshold" element={<Threshold />} />
                <Route path="/affiliate" element={<Affiliate />} />
                <Route path="/affiliateReport" element={<AffiliateReport />} />
                <Route path="/child-of-player/:userId" element={<AffiliateChild />} />
                <Route path="/" element={<Navigate to="/users" />} /> {/* Redirect to /users /}
                {/ Add other routes as needed */}
              </Routes>
            </div>
          </>
        ) : (
          <Login />
        )}
      </div>
    </Router>
  );
}

export default App;
