import React, { useState, useEffect } from 'react';
import { ChakraProvider, Input, useToast, Box, Table, Thead, Tbody, Tr, Th, Td, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, FormControl, FormLabel } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import axios from 'axios';

function Affiliate() {
    const [affiliates, setAffiliates] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editingAffiliate, setEditingAffiliate] = useState(null);
    const toast = useToast();

    useEffect(() => {
        fetchAffiliates();
    }, []);

    const fetchAffiliates = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/retrieve-affiliates`, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            if (response.data.success) {
                setAffiliates(response.data.data);
            } else {
                toast({
                    title: "Failed to fetch affiliates.",
                    description: response.data.message,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        } catch (err) {
            toast({
                title: "Error fetching affiliates",
                description: err.toString(),
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleEditClick = (affiliate) => {
        setEditingAffiliate(affiliate);
        setIsEditModalOpen(true);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditingAffiliate({ ...editingAffiliate, [name]: value });
    };

    const handleSubmitEditAffiliate = async () => {
        try {
            const { userId, rakebackPercentage, lossbackPercentage } = editingAffiliate;
            await axios.put(`${process.env.REACT_APP_API_URL}/api/admin/affiliate/edit-commission/${userId}`, {
                rakebackPercentage,
                lossbackPercentage
            }, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });

            fetchAffiliates();
            setIsEditModalOpen(false);
            toast({
                title: "Affiliate commission updated.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (err) {
            const errorMessage = err.response && err.response.data.message ? err.response.data.message : "An unexpected error occurred.";
            toast({
                title: "Error updating affiliate commission",
                description: errorMessage,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    return (
        <ChakraProvider>
            <Box className="App" p={4}>
                <Box className="search-bar-container" mb={4}>
                    <Input
                        type="text"
                        placeholder="Search player name..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
                    />
                </Box>

                <Box fontSize="2xl" fontWeight="bold" mb={4}>
                    Parent Affiliate
                </Box>

                <Table variant="striped" colorScheme="gray">
                    <Thead>
                        <Tr>
                            <Th>User ID</Th>
                            <Th>Player Name</Th>
                            <Th>Rakeback Percentage</Th>
                            <Th>Lossback Percentage</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {affiliates
                            .filter((affiliate) => affiliate.username.toLowerCase().includes(searchQuery))
                            .map((affiliate) => (
                                <Tr key={affiliate.userId}>
                                    <Td>{affiliate.userId}</Td>
                                    <Td>
                                        <Link to={`/child-of-player/${affiliate.userId}`} state={{ userName: affiliate.username }} style={{ cursor: 'pointer', color: 'blue' }}>
                                            {affiliate.username}
                                        </Link>
                                    </Td>
                                    <Td>{affiliate.rakebackPercentage}%</Td>
                                    <Td>{affiliate.lossbackPercentage}%</Td>
                                    <Td>
                                        <Button size="sm" colorScheme="blue" onClick={() => handleEditClick(affiliate)}>
                                            Edit Commission Percentage
                                        </Button>
                                    </Td>
                                </Tr>
                            ))}
                    </Tbody>
                </Table>

                {/* Edit Affiliate Commission Modal */}
                <Modal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Edit Affiliate Commission (ID: {editingAffiliate?.userId})</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            <FormControl>
                                <FormLabel>Rakeback Percentage</FormLabel>
                                <Input
                                    name="rakebackPercentage"
                                    type="number"
                                    value={editingAffiliate?.rakebackPercentage}
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                            <FormControl mt={4}>
                                <FormLabel>Lossback Percentage</FormLabel>
                                <Input
                                    name="lossbackPercentage"
                                    type="number"
                                    value={editingAffiliate?.lossbackPercentage}
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={handleSubmitEditAffiliate}>
                                Save Changes
                            </Button>
                            <Button onClick={() => setIsEditModalOpen(false)}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Box>
        </ChakraProvider>
    );
}

export default Affiliate;
