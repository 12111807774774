import React, { useState, useEffect } from 'react';
import { ChakraProvider, Box, useToast, Table, Thead, Tbody, Tr, Th, Td, Switch } from '@chakra-ui/react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from 'axios';
import './homeCategoryOrder.css';

function HomeCategoryOrder() {
    const [categories, setCategories] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const toast = useToast();

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/retrieve-game-categories`, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            if (response.data.success) {
                setCategories(response.data.data);
            } else {
                toast({
                    title: "Failed to fetch game categories.",
                    description: response.data.message,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        } catch (err) {
            toast({
                title: "Error fetching game categories",
                description: err.toString(),
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleDragEnd = async (result) => {
        if (!result.destination) return;

        const updatedCategories = Array.from(categories);
        const [reorderedCategory] = updatedCategories.splice(result.source.index, 1);
        updatedCategories.splice(result.destination.index, 0, reorderedCategory);

        // Update the homeCategoryOrder based on the new index
        updatedCategories.forEach((category, index) => {
            category.homeCategoryOrder = index + 1;
        });

        setCategories(updatedCategories);

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/update-game-category-rankings`, { categories: updatedCategories }, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            toast({
                title: "Category rankings updated.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (err) {
            const errorMessage = err.response && err.response.data.message ? err.response.data.message : "An unexpected error occurred.";
            toast({
                title: "Error updating category rankings",
                description: errorMessage,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleToggleActive = async (category) => {
        try {
            const updatedCategory = { ...category, homeCategoryIsActive: !category.homeCategoryIsActive };
            await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/game-category/activate-deactivate/${category.categoryId}`, {
                homeCategoryIsActive: updatedCategory.homeCategoryIsActive
            }, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            setCategories((prevCategories) => prevCategories.map(c => c.categoryId === category.categoryId ? updatedCategory : c));
            toast({
                title: `Category ${updatedCategory.homeCategoryIsActive ? 'activated' : 'deactivated'}.`,
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (err) {
            toast({
                title: "Error updating category status",
                description: err.toString(),
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    return (
        <ChakraProvider>
            <Box className="App" p={4}>
                {/* <Box className="search-bar-container" mb={4}>
                    <Input
                        type="text"
                        placeholder="Search category..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
                    />
                </Box> */}

                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="categories">
                        {(provided) => (
                            <Table {...provided.droppableProps} ref={provided.innerRef} variant="striped" colorScheme="gray">
                                <Thead>
                                    <Tr>
                                        <Th>Category ID</Th>
                                        <Th>Category Name</Th>
                                        <Th>Home Category Order</Th>
                                        <Th>Is Active</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {categories
                                        .filter((category) => category.categoryName.toLowerCase().includes(searchQuery))
                                        .map((category, index) => (
                                            <Draggable key={category.categoryId} draggableId={category.categoryId.toString()} index={index}>
                                                {(provided) => (
                                                    <Tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                        <Td>{category.categoryId}</Td>
                                                        <Td>{category.categoryName}</Td>
                                                        <Td>{category.homeCategoryOrder}</Td>
                                                        <Td>
                                                            <Switch
                                                                isChecked={category.homeCategoryIsActive}
                                                                onChange={() => handleToggleActive(category)}
                                                            />
                                                        </Td>
                                                    </Tr>
                                                )}
                                            </Draggable>
                                        ))}
                                    {provided.placeholder}
                                </Tbody>
                            </Table>
                        )}
                    </Droppable>
                </DragDropContext>
            </Box>
        </ChakraProvider>
    );
}

export default HomeCategoryOrder;
