import React, { useState, useEffect } from 'react';
import { ChakraProvider, Box, useToast, Table, Thead, Tbody, Tr, Th, Td, Switch } from '@chakra-ui/react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from 'axios';
import './homeListingOrder.css';

function HomeListingOrder() {
    const [listings, setListings] = useState([]);
    const toast = useToast();

    useEffect(() => {
        fetchListings();
    }, []);

    const fetchListings = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/retrieve-listing-order`, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            if (response.data.success) {
                setListings(response.data.data);
            } else {
                toast({
                    title: "Failed to fetch listings.",
                    description: response.data.message,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        } catch (err) {
            toast({
                title: "Error fetching listings",
                description: err.toString(),
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleDragEnd = async (result) => {
        if (!result.destination) return;

        const updatedListings = Array.from(listings);
        const [reorderedListing] = updatedListings.splice(result.source.index, 1);
        updatedListings.splice(result.destination.index, 0, reorderedListing);

        // Update the order based on the new index
        updatedListings.forEach((listing, index) => {
            listing.order = index + 1;
        });

        setListings(updatedListings);

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/update-listing-order`, { listings: updatedListings }, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            toast({
                title: "Listing order updated.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (err) {
            const errorMessage = err.response && err.response.data.message ? err.response.data.message : "An unexpected error occurred.";
            toast({
                title: "Error updating listing order",
                description: errorMessage,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleToggleActive = async (listing) => {
        try {
            const updatedListing = { ...listing, isActive: !listing.isActive };
            await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/listing-order/activate-deactivate/${listing.id}`, {
                isActive: updatedListing.isActive
            }, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            setListings((prevListings) => prevListings.map(l => l.id === listing.id ? updatedListing : l));
            toast({
                title: `Listing ${updatedListing.isActive ? 'activated' : 'deactivated'}.`,
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (err) {
            toast({
                title: "Error updating listing status",
                description: err.toString(),
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    return (
        <ChakraProvider>
            <Box className="App" p={4}>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="listings">
                        {(provided) => (
                            <Table {...provided.droppableProps} ref={provided.innerRef} variant="striped" colorScheme="gray">
                                <Thead>
                                    <Tr>
                                        <Th>Listing ID</Th>
                                        <Th>Listing Name</Th>
                                        <Th>Order</Th>
                                        <Th>Is Active</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {listings.map((listing, index) => (
                                        <Draggable key={listing.id} draggableId={listing.id.toString()} index={index}>
                                            {(provided) => (
                                                <Tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                    <Td>{listing.id}</Td>
                                                    <Td>{listing.listingName}</Td>
                                                    <Td>{listing.order}</Td>
                                                    <Td>
                                                        <Switch
                                                            isChecked={listing.isActive}
                                                            onChange={() => handleToggleActive(listing)}
                                                        />
                                                    </Td>
                                                </Tr>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </Tbody>
                            </Table>
                        )}
                    </Droppable>
                </DragDropContext>
            </Box>
        </ChakraProvider>
    );
}

export default HomeListingOrder;
